<template>
  <div class="profile">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 text-center relative">
        <h1 class="logo-text pt-5">
          Customised <span>Clothing</span>
        </h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 text-center">
        <h3 class="pt-5">
          Reset password request
        </h3>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 text-center">
        <p>Please enter your email below and we will send you a link to reset your password.</p>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6 medium-offset-3 text-center">
        <input
          v-model="email"
          class="sign-up-form-input"
          type="text"
          required
          placeholder="Email" />
      </div>
    </div>
    <div class="grid-x grid-padding-x pb-10">
      <div class="cell small-6 medium-3 medium-offset-3">
        <button @click="requestReset" class="button rounded-tall expanded">Send</button>
      </div>
    </div>
    <div
      @click="message = ''"
      v-if="message.length > 0"
      class="message">
      {{ message }}
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: '',
      message: '',
    };
  },
  methods: {
    requestReset() {
      const postData = {};
      postData.email = this.email;
      axios.post('/customers/requestPasswordReset.json', postData)
        .then((response) => {
          this.message = response.data;
        });
    },
  },
};
</script>
